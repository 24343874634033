export const setAwinCookieIfAwcParamExistInUrl = () => {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window?.location?.search);
    const awcParam = urlParams.get('awc');
    if (awcParam) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 365);
      document.cookie = `awc=${awcParam}; expires=${expirationDate.toUTCString()}; path=/; samesite=strict`;
    }
  }
};
