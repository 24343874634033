'use client';

import { setAwinCookieIfAwcParamExistInUrl } from '@/tracking/awin';
import { PropsWithChildren, useEffect } from 'react';

export const AwinTracker = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    setAwinCookieIfAwcParamExistInUrl();
  }, []);
  return children;
};
